@use '../abstract/mixins' as mixin;
@use "../abstract/responsive" as rwd;

$c-white: #FFFFFF;

.presidentHero {
  @include mixin.bgCover();
  position: relative;
  overflow: hidden;

  .heroPhoto img {
    height: auto;
    width: 100%;
    object-fit: cover;

    @include rwd.breakpoint(mobile) {
      max-height: 250px;
    }
  }

  .closeVideo {
    @include mixin.flexCenter();
    @include mixin.position(absolute, -70px, 0);
    border: none;
    border-radius: 0;
    background-color: var(--main-color);
    color: $c-white;
    transition: 0.4s;
    font-size: 40px;
    width: 50px;
    height: 50px;
    z-index: 3;
    cursor: pointer;

    .icon-close {
      font-size: 22px;
    }
  }

  .heroVideoPlay {
    @include mixin.centerer();
    @include mixin.makeCircle(70px, var(--main-color));
    position: absolute;
    z-index: 3;
    transition: 0.4s;
    font-size: 28px;
    cursor: pointer;
    border: none;
    outline: none;
    color: $c-white;

    @include rwd.breakpoint(phonesDown) {
      width: 50px;
      height: 50px;

      .player {
        width: 18px;
        height: 18px;
      }
    }

    .player {
      @include mixin.position(absolute, 50%, false, false, 55%);
      transform: translate(-55%, -50%);
    }
  }

  #heroVideo {
    width: 100%;

    @include rwd.breakpoint(mobileUp) {
      &.heroVideoMobile {
        display: none;
      }
    }

    @include rwd.breakpoint(mobile) {
      &.heroVideoDesktop {
        display: none;
      }
    }
  }

  #closeVideo.show {
    top: 0;
  }

  &.play {
    .heroVideoPlay {
      display: none;
    }
  }
}